﻿import { useGeneral } from '../js/util/general';

const { getScrollbarWidth } = useGeneral();

// Set global variables
if (!window.KnowitOptimizelyFramework) {
  window.KnowitOptimizelyFramework = {
    YouTube: null,
    BlockMediaScriptIsInitialized: false,
  };
}

// Imports
import "../../Features/Layout/Features/Header/Features/Menu/js/menu";
import "../../Features/Layout/Features/Header/Features/Disturbance/js/disturbance";
import "../../Features/Shared/Modal/_js/modal.ts";

// Set scrollbar width as CSS variable
if (!document.documentElement.style.getPropertyValue('--scrollbar-width')) {
  document.documentElement.style.setProperty('--scrollbar-width', getScrollbarWidth() + "px");
}

// If there is a visible scrollbar, add a class to the body
if (document.body.scrollHeight > window.innerHeight + 10) {
  document.body.classList.add('body--scrollbar-visible');
}